import React, { FunctionComponent, useState } from 'react'
import Spinner from 'react-spinner-material'
import styled from 'styled-components'
import { zIndex } from '../helpers/z-index'
import { colors } from '../constants'
import { breakpointDown, breakpointUp } from '../helpers/breakpoints'
import { linearGradient } from '../helpers/linearGradient'
import { isElementInViewport } from '../helpers/isElementInViewport'

interface IProps {
  children: any
  onClick: (event: React.MouseEvent<HTMLElement>) => any
  disabled?: boolean
  loading?: boolean
  tabIndex?: number
  ariaLabel?: string
  greyscale?: boolean
  inverted?: boolean
  focused?: boolean
}

const Button: FunctionComponent<IProps> = ({
  children,
  disabled,
  loading,
  tabIndex,
  onClick,
  ariaLabel,
  greyscale,
  inverted,
  focused,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false)

  const aria = ariaLabel ? ariaLabel : typeof children === 'string' ? children : undefined

  return (
    <Root
      onClick={(event: any) => onClick(event)}
      onTouchStart={() => {
        setIsActive(true)
      }}
      onTouchEnd={() => {
        setIsActive(false)
      }}
      aria-label={aria}
      tabIndex={tabIndex}
      onFocus={(ev) => {
        if (!isElementInViewport(ev.currentTarget)) {
          ev.currentTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          })
        }
      }}
      $isActive={isActive}
      $disabled={disabled}
      $loading={loading}
      $greyscale={greyscale}
      $inverted={inverted}
      $focused={focused}
    >
      <StyledLoader $loading={loading}>
        <Spinner size={26} color={inverted ? colors.steelGray : colors.white} width={2} visible />
      </StyledLoader>
      <StyledText
        tabIndex={-1}
        $loading={loading}
        $disabled={disabled}
        $greyscale={greyscale}
        $inverted={inverted}
        $focused={focused}
      >
        {children}
      </StyledText>
    </Root>
  )
}
export default Button

const Root = styled.button<{
  $isActive: boolean
  $disabled?: boolean
  $loading?: boolean
  $greyscale?: boolean
  $inverted?: boolean
  $focused?: boolean
}>`
  margin-bottom: 0.5rem;
  ${breakpointUp('phone')} {
  }
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  border-radius: ${(props) => props.theme.buttonBorderRadius};
  ${(props) =>
    linearGradient(
      props.$greyscale
        ? [colors.gray, colors.paleGray]
        : props.$inverted
        ? [colors.white, colors.white]
        : [props.theme.secondaryColor, props.theme.primaryColor]
    )}
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
  margin-left: -1px; /* Fixes a glitch in Chrome where a yellow border shows sometimes on the left */

  font-family: 'akzidenz-grotesk', sans-serif;

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.$inverted &&
    `
    border: 0.25rem solid ${props.$focused ? props.theme.primaryColor : colors.steelGray};
    &:hover {
      border: 0.25rem solid ${props.theme.primaryColor};
    }
  `}

  ${(props) =>
    props.$inverted &&
    !props.$focused &&
    `
    border: 0.25rem solid ${props.$focused ? props.theme.primaryColor : colors.steelGray};

    &:hover + & {
      border: 0.25rem solid ${colors.steelGray};
    }
  `}
    

  ${(props) =>
    props.$loading &&
    `
    &:hover {
      /* "Undo" the default hover state */
      background-position: right bottom;
      cursor: default;
    }
  `}

  ${(props) =>
    props.disabled &&
    !props.$loading &&
    `
    pointer-events: none;
    cursor: default;
    background-position: right bottom;
    background: ${colors.gray};
  `}

  ${breakpointUp('phone')} {
    min-width: 15.625rem;
    width: auto;
    &:hover {
      background-position: left bottom;
    }

    &:hover .text {
      color: ${colors.gray};
    }
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  ${breakpointDown('phone')} {
    ${(props) => props.$isActive && `transform: scale(0.9);`}
  }
`
const StyledLoader = styled.span<{ $loading?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
  transition-duration: 0.2s;
  transition-delay: 0s;
  z-index: ${zIndex.overlay};

  ${(props) =>
    props.$loading &&
    `
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.2s;
  `}
`
const StyledText = styled.span<{
  $loading?: boolean
  $disabled?: boolean
  $greyscale?: boolean
  $inverted?: boolean
  $focused?: boolean
}>`
  /* the text fills the button because it takes the focus to avoid focus border on button mouse click */
  position: relative;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${(props) =>
    props.$greyscale || props.$inverted ? (props.$focused ? colors.black : colors.steelGray) : colors.white};
  transition: all 0.3s;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  padding: 0.9375rem 2rem;

  border-radius: ${(props) => props.theme.buttonBorderRadius};

  &:focus {
    outline: none;
  }

  /* Animating box-shadow hurts performance so we animate the opacity on a pseudo element on .text instead  */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: ${(props) => props.theme.buttonBorderRadius};
    box-shadow: 0 0 0 0.25rem ${(props) => props.theme.primaryColor};
    transition: opacity 0.3s ease;
  }

  ${Root}:focus > & {
    background: ${(props) => (props.$inverted ? props.theme.primaryColor : colors.white)};
    color: ${(props) => (props.$greyscale ? colors.black : props.$inverted ? colors.white : props.theme.primaryColor)};
    transition: all 0.3s ease;
  }

  ${(props) =>
    props.$inverted &&
    `
    ${Root}:hover + ${Root} > & {
      color: ${colors.steelGray};
    }
    &:hover {
      color: ${colors.black};
    }`}

  ${Root}:focus > &::after {
    opacity: 1;
  }

  ${(props) =>
    props.$loading &&
    `
    opacity: 0;
    pointer-events: none;
  `}

  ${(props) =>
    (props.$disabled || props.$greyscale) &&
    `
    border-radius: ${props.theme.buttonBorderRadius};

    ${Root} > &::after {
      border-radius: ${props.theme.buttonBorderRadius};
    }
  `}

  ${(props) =>
    props.$disabled &&
    `
    background: ${colors.gray};

    ${Root} > &::after {
      box-shadow: 0 0 0 0.1875rem ${props.theme.primaryColor};
    }
  `}

  ${(props) =>
    props.$greyscale &&
    `
    ${linearGradient([colors.silverGray, colors.paleGray])}
    background-size: 200% 100%;
    background-position: right bottom;
    &:hover {
      background-position: left bottom;
    }

    ${Root} > &::after {
      box-shadow: 0 0 0 0.1875rem ${colors.steelGray};
    }
  `}
`
