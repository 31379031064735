import React from 'react'
import styled from 'styled-components'
import { zIndex } from '../helpers/z-index'
import { colors } from '../constants'
import { useAppState } from '../Store'
interface Props {
  error?: {
    message: string
    visible: boolean
  }
}
export const ErrorNotificationComponent = ({ error }: Props) => {
  return (
    <Root $show={error && error.visible}>
      <Message>{error && error.message}</Message>
    </Root>
  )
}

export const ErrorNotification = () => {
  const { state } = useAppState()

  return ErrorNotificationComponent({ error: state.error })
}

const Root = styled.div<{ $show?: boolean }>`
  z-index: ${zIndex.fixed}; /*To display on top of header*/
  position: fixed;
  top: -5rem;
  left: 0;
  right: 0;
  height: 5rem;
  display: flex;
  align-items: center;
  background-color: ${colors.errorRed};
  color: white;

  animation: ${(props) =>
    props.$show !== undefined ? (props.$show ? 'animateDown 0.4s ease-out 0.5s' : 'animateUp 0.3s ease-in') : 'none'};
  animation-fill-mode: forwards;

  @keyframes animateDown {
    from {
      top: -5rem;
    }
    to {
      top: 0rem;
    }
  }

  @keyframes animateUp {
    from {
      top: 0rem;
    }
    to {
      top: -5rem;
    }
  }
`
const Message = styled.div`
  flex: 1;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
`
