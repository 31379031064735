import React, { useState, useCallback } from 'react'
import Spinner from 'react-spinner-material'
import styled from 'styled-components'

import { colors } from '../../../constants'
import { zIndex } from '../../../helpers/z-index'

interface Props {
  onToggle: () => Promise<void>
  selected: boolean
}

interface StyledButtonTextProps {
  $selected: boolean
  $loading?: boolean
}
export const ToggleCoverageButton = ({ onToggle, selected }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleOnClick = useCallback(async () => {
    setIsLoading(true)
    await onToggle()
    setIsLoading(false)
  }, [onToggle])

  return (
    <StyledButton
      onClick={handleOnClick}
      onMouseDown={(event) => event.preventDefault()}
      aria-label={selected ? 'Fjarlægja' : 'Bæta við'}
      disabled={isLoading}
    >
      <StyledLoader $loading={isLoading}>
        <Spinner size={26} color={colors.gray} width={2} visible />
      </StyledLoader>
      <StyledButtonText $selected={selected} $loading={isLoading} tabIndex={-1}>
        {selected ? 'Fjarlægja' : 'Bæta við'}
        <StyledIcon $selected={selected} />
      </StyledButtonText>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  position: relative;
  border: 0.25rem solid ${colors.silverGray};
  background: white;
  line-height: 1.6875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin-top: 2rem;
  cursor: pointer;
  width: 100%;
  outline: none;

  &:focus {
    border: 0.25rem solid ${(props) => props.theme.primaryColor};
  }
`

const StyledButtonText = styled.span<StyledButtonTextProps>`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0.6875rem 1.25rem 0.625rem;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 500;
  color: ${(props) => (props.$selected ? colors.black : colors.steelGray)};
  transition: color 0.3s;
  ${(props) => props.$loading && 'opacity: 0;'}

  ${StyledButton}:hover & {
    color: ${colors.black};
  }

  ${StyledButton}:focus > & {
    color: ${(props) => (props.$selected ? colors.white : colors.black)};
    ${(props) => props.$selected && `background-color: ${props.theme.primaryColor};`}
    &::after {
      opacity: ${(props) => (props.$selected ? '0' : '1')};
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.$selected ? '1' : '0')};
    box-shadow: 0 0 0 0.25rem ${(props) => props.theme.primaryColor};
    transition: opacity 0.3s ease;
  }

  @media screen and (min-width: 700px) {
    height: 3rem;
    width: 100%;
    padding: 0 0.75rem;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 1.5rem;
  }
`

const StyledIcon = styled.span<{ $selected?: boolean }>`
  transition: transform 0.3s;
  ${(props) => props.$selected && 'transform: rotate(45deg);'}

  &::after, &::before {
    content: '';
    z-index: ${zIndex.overlay};
    display: block;
    height: 0.125rem;
    width: 1rem;
    background: ${colors.black};
    border-radius: 0.0625rem;
    margin-top: -0.125rem;
  }

  &::after {
    transform: rotate(90deg);
  }

  ${StyledButton}:focus > &,
  ${StyledButton}:focus &::after,
  ${StyledButton}:focus &::before {
    background: ${(props) => (props.$selected ? colors.white : colors.black)};
  }
`

const StyledLoader = styled.span<{ $loading?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.$loading ? '1' : '0')};
  pointer-events: none;
  transform: ${(props) => (props.$loading ? 'scale(1)' : 'scale(0.8)')};
  transition-duration: 0.2s;
  transition-delay: ${(props) => (props.$loading ? '0.2s' : '0s')};
  z-index: ${zIndex.overlay};
`
