import styled from 'styled-components'
import React from 'react'
import { zIndex as zIdx } from '../helpers/z-index'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useAppState } from '../Store'
import { Brand } from '../api/types'

interface Props {
  left?: string
  delay?: string
  visible?: boolean
  zIndex?: number
}
export const Curtain = function ({ left, delay, visible, zIndex = zIdx.curtain }: Props) {
  const { state } = useAppState()
  return (
    <TransitionGroup component={null}>
      {visible && (
        <CSSTransition key="curtain" timeout={800} classNames="transition">
          <Root $zIndex={zIndex} $brandType={state.brandType} $transitionDelay={delay} $left={left} />
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

const Root = styled.div<{
  $zIndex: number
  $brandType: Brand
  $transitionDelay?: string
  $left?: string
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => props.$zIndex};

  transition-delay: ${(props) => props.$transitionDelay};

  background-color: ${(props) => props.theme.primaryColor};
  &.transition-enter {
    top: 100%;
    transition: top 0.4s ease-in;
  }
  &.transition-enter.transition-enter-active {
    top: 0;
  }
  &.transition-exit {
    bottom: 0;
    transition: bottom 0.4s ease-out;
  }
  &.transition-exit.transition-exit-active {
    bottom: 100%;
  }
`
