import React, { ReactNode } from 'react'
import { breakpointUp } from '../helpers/breakpoints'
import { propBetween } from '../helpers/between'
import styled from 'styled-components'

interface Props {
  align?: 'left' | 'center' | 'right'
  text: string | ReactNode
}

export const ChatSectionTitle: React.FC<Props> = ({ align = 'center', text }: Props) => (
  <Root $align={align}>{text}</Root>
)

const Root = styled.h1<{ $align: 'left' | 'center' | 'right' }>`
  ${propBetween('font-size', 1.25, 2.1875)}
  line-height: 1.3;
  font-weight: 700;
  text-align: center;

  ${breakpointUp('tablet')} {
    line-height: 1.2;
  }
`
