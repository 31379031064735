import React, { useEffect } from 'react'

import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import { ChatSection } from '../../components/ChatSection'
import { useTitle } from '../../hooks/useTitle'
import { useAppState } from '../../Store'
import { Brand } from '../../api/types'

interface Props {
  brandType: Brand
}

// NB. This text is also used in the AdminClosed component
export const openingAgainTime = 'kl. 09:00 mánudaginn 24. mars.'

const ClosedComponent: React.FC<Props> = ({ brandType }: Props) => {
  return (
    <ChatSection>
      <ChatSectionTitle
        text={`Vegna sameiningar TM og Landsbankans er tímabundið ekki hægt að kaupa ${brandType} tryggingu á vefnum. Við opnum aftur eftir ${openingAgainTime}.`}
      />
    </ChatSection>
  )
}

export const Closed = () => {
  const { setTitle } = useTitle()
  const { state } = useAppState()

  useEffect(() => {
    setTitle('Upphaf')
  }, [setTitle])

  return ClosedComponent({ brandType: state.brandType })
}
