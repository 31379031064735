import React, { useState, useMemo, useEffect } from 'react'
import { useParams, useLocation, matchPath } from 'react-router-dom'
import styled from 'styled-components'

import { api } from '../../api'
import {
  Brand,
  Coverage as CoverageType,
  CoverageInput,
  EnteredNumberProperty,
  Policy,
  SupportedModels,
} from '../../api/types'
import Button from '../../components/Button'
import { Loading } from '../../components/Loading'
import { formatKronur } from '../../helpers/formatKronur'
import { breakpointDown, breakpointUp } from '../../helpers/breakpoints'

import { Coverage } from './components/Coverage'
import { CoverageCard } from './components/CoverageCard'
import { CoverageCards } from './components/CoverageCards'
import { CoverageSlider } from './components/CoverageSlider'
import { DetailsHeader } from './components/DetailsHeader'
import { Select } from '../../components/Select'
import { Footer } from '../../components/Footer'
import { AddedValues } from './components/AddedValues'
import { Information } from './components/Information'
import { useRouteTransition } from '../../hooks/useRouteTransition'
import { Input } from '../../components/Input'
import { updatePolicy, useAppState, removePolicy } from '../../Store'
import Spinner from 'react-spinner-material'
import { colors, CoverageIds, PropertyIds } from '../../constants'
import { numberInputChangeHandler } from '../../helpers/numberInputHandler'
import { useExceptionHandler } from '../../hooks/useExceptionHandler'
import { useTitle } from '../../hooks/useTitle'
import { useDispatchErrorNotification } from '../../hooks/useDispatchErrorNotification'

// Should format number and return string
const optionToSelect = (value: number) => ({
  value,
  title: `${formatKronur(value)} kr.`,
})

const debounce = <TArgs extends any[], TResult>(
  ms: number,
  fn: (...args: TArgs) => TResult
): ((...args: TArgs) => void) => {
  let timeout: number | null = null
  return (...args: TArgs) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, ms) as unknown as number
  }
}

interface Props {
  isAdmin: boolean
  createdByUser: boolean
  canRemovePolicy: boolean
  isRemoving: boolean
  policy: Policy
  policyNumber: string
  vehicleDetails: {
    description: string
    year: number
    licensePlate: string
  }
  modelType: SupportedModels
  windShieldCoverage: CoverageType
  mandatoryCoverage: CoverageType
  kaskoCoverageDetails: {
    coverage: CoverageType
    entryPriceTotal: number
    selfRiskOptions: Array<number>
    selfRiskAmount: number
    coverageOptions: Array<number>
    coverageAmount: number
    selected: boolean
  }
  handleKaskoSelfRiskChange: (value: string) => Promise<void>
  handleKaskoCoverageChange: (value: string) => Promise<void>
  toggleCoverage: (policyNumber: string, coverage: CoverageType) => Promise<void>
  goToNextPage(): void
  setTitle(title: string): void
  removePolicy: () => Promise<void>
  dispatchErrorNotification: (message: string) => void
  contactEmail: string
}

const DetailsComponent: React.FC<Props> = ({
  isAdmin,
  createdByUser,
  canRemovePolicy,
  isRemoving,
  policy,
  policyNumber,
  vehicleDetails,
  modelType,
  windShieldCoverage,
  mandatoryCoverage,
  kaskoCoverageDetails,
  handleKaskoSelfRiskChange,
  handleKaskoCoverageChange,
  toggleCoverage,
  goToNextPage,
  setTitle,
  removePolicy,
  dispatchErrorNotification,
  contactEmail,
}: Props) => {
  const [kaskoAmount, setKaskoAmount] = useState(isAdmin ? 0 : kaskoCoverageDetails.coverageAmount)

  const [kaskoAmountLoading, setKaskoAmountLoading] = useState<boolean>(false)

  // Debounce so that when typing we don't flood the server
  // with requests.
  // This makes sure the function is called at most once every 1 second
  const debouncedHandleKaskoCoverageChange = useMemo(
    () =>
      debounce(1000, async (value: string) => {
        setKaskoAmountLoading(true)
        await handleKaskoCoverageChange(value)
        setKaskoAmountLoading(false)
      }),
    [handleKaskoCoverageChange]
  )

  useEffect(() => {
    setTitle('Yfirlit')
  })

  const handleAdminContinue = () => {
    if (Boolean(kaskoAmount)) {
      goToNextPage()
    } else {
      dispatchErrorNotification('Það þarf að fylla út í markaðsvirðið')
    }
  }

  return (
    <Root>
      {isAdmin ? (
        <DetailsTitle>
          Upplýsingar um {modelType} tryggingu fyrir {vehicleDetails.description} {vehicleDetails.year}
        </DetailsTitle>
      ) : (
        <DetailsHeader
          title={<>{modelType}&shy;trygging</>}
          text={`${vehicleDetails.description} ${vehicleDetails.year}`}
          reference={policy.subjects[0].reference}
          modelType={modelType}
        />
      )}
      <Coverage
        title="Skyldu&shy;trygging"
        description="Samkvæmt lögum er skylt að tryggja alla bíla með ábyrgðar- og slysatryggingu. Ábyrgðartrygging bætir tjón sem aðrir verða fyrir af völdum bílsins. Slysatrygging bætir slys á ökumanni og einnig eiganda ef hann er farþegi í bílnum."
        price={mandatoryCoverage.entryPriceTotal.total}
        borderBottom
      >
        {!isAdmin && <CoverageSlider coverages={[mandatoryCoverage]} />}
      </Coverage>

      <Coverage
        title="Kaskó&shy;trygging"
        description="Kaskótrygging bætir tjón á bílnum þínum ef þú lendir í árekstri og getur því sparað þér heilmikinn kostnað. Kaskó bætir einnig skemmdir sem verða á bílnum við innbrot, útafakstur, bruna og fleira."
        price={kaskoCoverageDetails.entryPriceTotal}
        borderBottom
        selected={kaskoCoverageDetails.selected}
        onToggleSelected={async () => {
          await toggleCoverage(policyNumber, kaskoCoverageDetails.coverage)
        }}
      >
        <CoverageCards>
          <CoverageCard
            title="Eigin áhætta"
            description="Þú velur upphæðina á eigin áhættu, sem er sá hluti sem þú greiðir ef til tjóns kemur."
          >
            <SelectBox>
              <Select
                label="Eigin áhætta"
                disabled={!kaskoCoverageDetails.selected}
                options={kaskoCoverageDetails.selfRiskOptions.map((i) => {
                  return optionToSelect(i)
                })}
                onChange={handleKaskoSelfRiskChange}
                selected={optionToSelect(kaskoCoverageDetails.selfRiskAmount)}
              />
            </SelectBox>
          </CoverageCard>

          <CoverageCard
            title="Markaðsvirði bíls"
            description="Hámark bóta miðast við markaðsvirði bíls svo best er að miða við það, þ.e. hvað það kostar að kaupa sambærilegan bíl ef viðkomandi lendir í altjóni."
          >
            <InputBox>
              <Input
                type="text"
                disabled={(!isAdmin && !createdByUser) || !kaskoCoverageDetails.selected}
                onChange={(inputValue, event) => {
                  const [value, selectionStart] = numberInputChangeHandler(
                    formatKronur(kaskoAmount),
                    inputValue,
                    event.target
                  )
                  setKaskoAmount(value)
                  const target = event.target
                  // Force this to run after react has run the state update
                  setTimeout(() => target.setSelectionRange(selectionStart, selectionStart))
                  debouncedHandleKaskoCoverageChange(String(value))
                }}
                value={formatKronur(kaskoAmount)}
                label=""
              />
              <AbsoluteRight>
                <Spinner size={26} color={colors.black} width={2} visible={kaskoAmountLoading} />
              </AbsoluteRight>
            </InputBox>
          </CoverageCard>
        </CoverageCards>
        {!isAdmin && <CoverageSlider coverages={[kaskoCoverageDetails.coverage]} />}
      </Coverage>
      <Coverage
        title="Bílrúðu&shy;trygging"
        description="Bílrúður geta auðveldlega brotnað og dýrt getur verið að skipta um þær. Þessi trygging bætir bæði viðgerðar- og ísetningarkostnað á þeim. Eigin áhætta í hverju tjóni er 20% af kostnaði ef skipt er um rúðu, en engin ef hún er löguð."
        price={windShieldCoverage.entryPriceTotal.total}
        borderBottom={!isAdmin}
        selected={windShieldCoverage!.selected}
        onToggleSelected={() => toggleCoverage(policyNumber, windShieldCoverage!)}
      >
        {!isAdmin && <CoverageSlider coverages={[windShieldCoverage]} />}
      </Coverage>
      <AddedValues modelType={modelType} />
      {!isAdmin && <Information usingSelfService={!isAdmin && createdByUser} contactEmail={contactEmail} />}
      {isAdmin ? (
        <ButtonWrapper $removeVisible={canRemovePolicy} style={{ marginBottom: '1rem' }}>
          <Button onClick={handleAdminContinue}>Halda áfram</Button>
        </ButtonWrapper>
      ) : (
        policy.terms[0] && (
          <ButtonWrapper $removeVisible={canRemovePolicy}>
            <Button
              onClick={() => window.open(policy.terms[0].url, '_blank', 'noopener')}
              aria-label={'Sjá skilmála - opnast í nýjum flipa'}
              greyscale
            >
              Sjá skilmála
            </Button>

            {canRemovePolicy && (
              <Button onClick={() => removePolicy()} aria-label={'Fjarlægja tryggingu'} loading={isRemoving}>
                Fjarlægja
              </Button>
            )}
          </ButtonWrapper>
        )
      )}
      <Footer
        onClick={goToNextPage}
        showCustomerViews={!isAdmin}
        price={policy.policyPeriod.price.total || 0}
        buttonText="Til baka"
        handleScrollBarWidth
      />
    </Root>
  )
}

export const Details = () => {
  const { routeTo } = useRouteTransition()
  const { quoteId, policyId } = useParams()
  const { handleException } = useExceptionHandler()
  const dispatchErrorNotification = useDispatchErrorNotification()
  const { pathname } = useLocation()
  const isAdmin = matchPath('/admin/*', pathname)?.pathnameBase === '/admin'
  const { setTitle } = useTitle()
  const { state, dispatch } = useAppState()
  const [isRemoving, setIsRemoving] = useState<boolean>(false)

  if (state.quote && state.quote.policies.length > 0 && state.vehicleModels.length > 0) {
    const policy = state.quote.policies.find((p) => p.number === policyId)

    if (policy === undefined) {
      return null
    }

    const kasko = policy.subjects[0].coverage.find((a) => a.typeId === CoverageIds.KaskoCoverage)
    const windShieldCoverage = policy.subjects[0].coverage.find((a) => a.typeId === CoverageIds.WindShieldCoverage)
    const mandatoryCoverage = policy.subjects[0].coverage.find((a) => a.typeId === CoverageIds.MandatoryCoverage)

    if (!kasko || !windShieldCoverage || !mandatoryCoverage) {
      throw new Error('No Kasko, windShieldCoverage, or mandatoryCoverage')
    }

    const selfRiskProp = kasko.properties.find((a): a is EnteredNumberProperty => a.id === PropertyIds.SelfRisk)
    const coverageProp = kasko.properties.find((a): a is EnteredNumberProperty => a.id === PropertyIds.CoverageAmount)

    if (!selfRiskProp || !coverageProp) {
      throw new Error('No selfRiskProp or coverageProp')
    }

    const vehicleModel = state.vehicleModels.find((vi) => vi.licencePlate === policy.subjects[0].reference)
    if (!vehicleModel) {
      throw new Error('Could not find vehicle model for car')
    }

    const policyNumber = policy.number

    const props: Props = {
      isAdmin: isAdmin,
      createdByUser: state.quote.createdByCurrentUser,
      canRemovePolicy: state.quote.policies.length > 1,
      isRemoving,
      policy: state.quote.policies[0],
      policyNumber,
      vehicleDetails: {
        description: policy.subjects[0].description,
        year: policy.subjects[0].modelYear,
        licensePlate: policy.subjects[0].reference,
      },
      modelType: vehicleModel.model,
      windShieldCoverage,
      mandatoryCoverage,
      kaskoCoverageDetails: {
        coverage: kasko,
        entryPriceTotal: kasko.entryPriceTotal.total || 0,
        selfRiskAmount: selfRiskProp.value || 0,
        selfRiskOptions: selfRiskProp.valueOptions,
        coverageAmount: coverageProp.value || 3000000,
        coverageOptions: coverageProp.valueOptions,
        selected: kasko!.selected,
      },
      handleKaskoSelfRiskChange: async (value: string) => {
        try {
          const coverage = [
            {
              typeId: CoverageIds.KaskoCoverage,
              properties: [{ id: PropertyIds.SelfRisk, value }],
            },
          ]
          const selfRiskPolicy = await api.updatePolicy(policyNumber, {
            coverage,
          })
          dispatch(updatePolicy(selfRiskPolicy))
        } catch (error) {
          handleException({
            error,
            dispatchErrorNotification: false,
          })
        }
      },
      handleKaskoCoverageChange: async (value: string) => {
        try {
          const coverage = [
            {
              typeId: CoverageIds.KaskoCoverage,
              properties: [{ id: PropertyIds.CoverageAmount, value }],
            },
          ]
          const coveragePolicy = await api.updatePolicy(policyNumber, {
            coverage,
          })
          dispatch(updatePolicy(coveragePolicy))
        } catch (error) {
          handleException({
            error,
            dispatchErrorNotification: false,
          })
        }
      },
      toggleCoverage: async (policyNbr: string, coverage: CoverageType) => {
        try {
          const toggledCoverage: CoverageInput = {
            typeId: coverage.typeId,
            selected: !coverage.selected,
          }

          if (!coverage.selected) {
            toggledCoverage.properties = [
              {
                id: PropertyIds.SelfRisk,
                value: String(props.kaskoCoverageDetails.selfRiskAmount),
              },
              {
                id: PropertyIds.CoverageAmount,
                value: String(props.kaskoCoverageDetails.coverageAmount),
              },
            ]
          }

          const toggleCoveragePolicy = await api.updatePolicy(policyNbr, {
            coverage: [toggledCoverage],
          })

          dispatch(updatePolicy(toggleCoveragePolicy))
        } catch (error) {
          handleException({
            error,
            dispatchErrorNotification: false,
          })
        }
      },
      goToNextPage() {
        if (quoteId) {
          if (isAdmin) {
            routeTo(`/admin/${quoteId}/senda-tilbod`)
          } else {
            routeTo(`/${quoteId}/yfirlit`)
          }
        }
      },
      removePolicy: async () => {
        setIsRemoving(true)
        if (window.confirm('Viltu fjarlægja þessa tryggingu?')) {
          try {
            await api.removePolicy(policyNumber)
            dispatch(removePolicy(policyNumber))
            routeTo(`/${quoteId}/yfirlit`)
          } catch (error) {
            setIsRemoving(false)
            dispatchErrorNotification('Eitthvað fór úrskeiðis. Vinsamlegast reynið síðar')
          }
        } else {
          setIsRemoving(false)
        }
      },
      setTitle,
      dispatchErrorNotification,
      contactEmail: state.brandType === Brand.Lexus ? 'info@lexustryggingar.is' : 'info@toyotatryggingar.is',
    }

    return <DetailsComponent {...props} />
  } else {
    return <Loading />
  }
}

export default Details

const Root = styled.div`
  margin: 0 6.5rem 14rem 6.5rem; /* 14rem is 7.5rem + the height of the footer. */

  ${breakpointDown('tablet')} {
    margin: 0 1.5rem 5rem 1.5rem;
  }
`

const DetailsTitle = styled.p`
  width: 40%;
  margin-bottom: 1rem;
  margin-top: 7.5rem;

  font-style: normal;
  font-weight: bold;
  font-size: 2.1875rem;
  line-height: 2.8125rem;
  letter-spacing: -0.015em;
`

const SelectBox = styled.div`
  width: 100%;
  margin-bottom: 4rem;
`

const InputBox = styled.div`
  position: relative;

  width: 100%;
  margin-bottom: 2.5rem;
`

const AbsoluteRight = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 1.5rem;
  bottom: 1.4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.625rem;
`

const ButtonWrapper = styled.div<{
  $removeVisible: boolean
  $stickyOnMobile?: boolean
}>`
  display: flex;
  justify-content: ${(props) => (props.$removeVisible ? 'space-between' : 'center')};
  margin-top: 5rem;

  ${breakpointDown('phone')} {
    margin-bottom: 10.5rem;
    ${(props) => props.$removeVisible && 'flex-direction: column'}
    > button {
      margin-bottom: 0.5rem;
    }
  }
  ${breakpointUp('phone')} {
    margin-top: 2.75rem;
    margin-bottom: 10.5rem;
  }
`
