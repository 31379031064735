import React from 'react'
import Switch from 'react-switch'
import styled from 'styled-components'

import { colors } from '../../../constants'

import { Price } from './Price'
import { ToggleCoverageButton } from './ToggleCoverageButton'
import { breakpointDown, breakpointUp } from '../../../helpers/breakpoints'
import { propBetween } from '../../../helpers/between'
import { Brand } from '../../../api/types'
import { useAppState } from '../../../Store'

interface Props {
  title: string
  description: string
  onToggleSelected?: (checked: boolean) => Promise<void>
  price?: number | null
  borderBottom?: boolean
  children?: any
  selected?: boolean
}

export const Coverage = ({
  title,
  description,
  price,
  borderBottom,
  children,
  selected = false,
  onToggleSelected,
}: Props) => {
  const { state } = useAppState()
  return (
    <Root $borderBottom={borderBottom}>
      <CoverageContainer>
        <DescriptionWrapper>
          <Heading>{title}</Heading>
          <div>{description}</div>
        </DescriptionWrapper>
        <ChildrenWrapper>{children}</ChildrenWrapper>
        <PriceWrapper>
          {(price || price === 0) && <Price amount={price} />}
          {onToggleSelected && (
            <>
              <VisibleBelowTablet>
                <Switch
                  checked={selected}
                  // disabled={priceOutdated}
                  onChange={onToggleSelected}
                  onColor={state.brandType === Brand.Lexus ? colors.lexusBlack : colors.toyotaRed}
                  offColor={colors.gray}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  id={title}
                  aria-label={title}
                />
              </VisibleBelowTablet>
              <ToggleButtonContainer>
                <ToggleCoverageButton selected={selected} onToggle={() => onToggleSelected(!selected)} />
              </ToggleButtonContainer>
            </>
          )}
        </PriceWrapper>
      </CoverageContainer>
    </Root>
  )
}
const Root = styled.article<{ $borderBottom?: boolean }>`
  background-color: ${colors.white};
  position: relative;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  padding: 5.5rem 0 0 0;
  ${(props) => props.$borderBottom && `border-bottom: 0.0625rem solid ${colors.silverGray};`}

  ${breakpointDown('tablet')} {
    border-bottom: 0.25rem solid ${colors.black};
  }
`

const CoverageContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  grid-template-areas: 'left right';
  margin-bottom: 5.5rem;

  ${breakpointDown('tablet')} {
    margin-bottom: 0;
    grid-template-columns: 1fr;
  }
`

const ChildrenWrapper = styled.div`
  grid-area: auto / left / auto / right;
`

const DescriptionWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 140%;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  justify-content: center;
  letter-spacing: 0.01em;
`

const PriceWrapper = styled.div`
  border-top: none;
  border-bottom: none;
  padding: 3rem 0 1.625rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  grid-area: right;
  text-align: right;
  width: fit-content;
  justify-self: flex-end;

  ${breakpointDown('tablet')} {
    width: 100%;
    margin-top: 5rem;
    grid-area: auto;
    border-top: 0.0625rem solid ${colors.silverGray};
    flex-direction: row;
    justify-content: space-between;
  }
`

const Heading = styled.h1`
  font-style: normal;
  font-weight: bold;
  ${propBetween('font-size', 2.5, 3.75)};
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: -0.012em;
  max-width: 26.5rem;
  margin: 3rem 0;
`

const VisibleBelowTablet = styled.div`
  ${breakpointUp('tablet')} {
    display: none;
  }
`

const ToggleButtonContainer = styled.div`
  width: 100%;
  ${breakpointDown('tablet')} {
    display: none;
  }
`
