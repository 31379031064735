import styled from 'styled-components'
import { colors } from '../constants'
import { zIndex } from '../helpers/z-index'
import { breakpointUp, breakpointDown } from '../helpers/breakpoints'
import { SupportedModels, Brand } from '../api/types'
import lexusImage from '../assets/lexus-header-image.jpg'
import toyotaImage from '../assets/toyota-header-image.jpg'
import { propBetween } from '../helpers/between'
const toyotaBackgroundStyle = `
  background-image: url(${toyotaImage});
  background-size: cover;
  background-position: center top;
`
const toyotaBackgroundStylePhone = `
background-size:cover;
background-position-x: -3rem;
`
const toyotaBackgroundStyleDesktopL = `
background-size:cover;
`

const lexusBackgroundStyle = `
  background-image: url(${lexusImage});
  background-size: cover;
  background-position: center center;
`

const lexusBackgroundStylePhone = `
background-size:cover;
background-position-x: -10rem;
`

const lexusBackgroundStyleDesktopL = `
background-size:cover;
`
export interface Props {
  $modelType?: SupportedModels
}
export const PageHeader = styled.header<Props>`
  ${(props) =>
    props.$modelType === SupportedModels.Lexus || props.theme.type === Brand.Lexus
      ? lexusBackgroundStyle
      : toyotaBackgroundStyle};
  background-repeat: no-repeat;
  height: calc(100vh - 6.5rem - 6.5rem - 7.5rem);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: ${colors.black};
  width: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -0.0625rem;
    ${propBetween('width', 4.5, 6.5)};
    ${propBetween('height', 4.5, 6.5)};
    background-color: ${colors.white};
  }

  ${breakpointUp('tablet')} {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  ${(props) =>
    props.$modelType === SupportedModels.Lexus || props.theme.type === Brand.Lexus
      ? lexusBackgroundStyleDesktopL
      : toyotaBackgroundStyleDesktopL};
  ${breakpointDown('tablet')} {
    z-index: ${zIndex.overlay};
  }
  ${breakpointDown('phone')} {
    height: calc(100vh - 6.5rem - 8rem - 7.5rem);
    ${(props) =>
      props.$modelType === SupportedModels.Lexus || props.theme.type === Brand.Lexus
        ? lexusBackgroundStylePhone
        : toyotaBackgroundStylePhone};
  }
`
