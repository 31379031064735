import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { SummaryCard } from './SummaryCard'
import { Policy } from '../../api/types'
import styled from 'styled-components'
import { breakpointUp, breakpointDown } from '../../helpers/breakpoints'
import { colors } from '../../constants'
import Footer from '../../components/Footer'
import { useRouteTransition } from '../../hooks/useRouteTransition'
import { api } from '../../api/api.tm'
import { useTitle } from '../../hooks/useTitle'
import { useAppState } from '../../Store'
import { LogOutButton } from '../../components/LogOutButton'

interface Props {
  policies: Policy[]
  goToNextPage: () => void
  isLoading: boolean
}

const getCarsThatAreAlreadyInsured = (policies: Policy[], existingPlateNumbers: string[]): Policy[] => {
  return policies.filter((p) => existingPlateNumbers.indexOf(p.subjects[0].reference) > -1)
}

const getRouteEnd = (cancellationCompanies: string[], currentPolicies: Policy[]): string => {
  // user has ökutækjatrygging at TM and at another insurance company
  if (cancellationCompanies.length > 0 && currentPolicies.length > 0) {
    return `/bill-thegar-tryggdur/TM-${cancellationCompanies.join('-')}`
  }
  // user has one or more ökutækjatrygging with another insurance company only
  else if (cancellationCompanies.length > 0) {
    return `/bill-thegar-tryggdur/${cancellationCompanies.join('-')}`
  }
  // user has one or more ökutækjatrygging at TM only
  else if (currentPolicies.length > 0) {
    switch (currentPolicies.length) {
      case 1:
        return `/bill-thegar-tryggdur/TM/${currentPolicies[0].subjects[0].reference}`
      default:
        return `/bill-thegar-tryggdur/TM`
    }
  }
  // user has no ökutækjatrygging for the cars in the policy/policies at TM or any other insurance company
  return '/kaupa'
}

const getSummaryCopy = (multiplePolicies: boolean) => {
  return multiplePolicies
    ? 'Vertu viss um að fara vel yfir nýju tryggingarnar þínar áður en þú kaupir þær. Hér fyrir neðan geturðu skoðað hverja tryggingu nánar og gert breytingar ef þörf er á.'
    : 'Vertu viss um að fara vel yfir nýju trygginguna þína áður en þú kaupir hana. Hér fyrir neðan geturðu skoðað hana nánar og gert breytingar ef þörf er á.'
}

const SummaryComponent: React.FC<Props> = ({ policies, goToNextPage, isLoading }: Props) => {
  const [isMounted, setIsMounted] = useState<boolean>(false)
  useEffect(() => {
    const timeoutId = setTimeout(() => setIsMounted(true), 0)
    return () => {
      clearTimeout(timeoutId)
    }
  })
  return (
    <>
      <Content>
        <Title $isMounted={isMounted}>YFIRLIT TRYGGINGA</Title>
        <Subtitle $isMounted={isMounted}>{getSummaryCopy(policies.length > 1)}</Subtitle>
      </Content>

      <Policies>
        {policies.map((policy, index) => (
          <SummaryCard key={index} policyId={policy.number} reverse={index % 2 !== 0} />
        ))}
      </Policies>
      <LogOutButtonWrapper>
        <LogOutButton invisible />
      </LogOutButtonWrapper>

      <Footer
        buttonText="Kaupa"
        buttonLoading={isLoading}
        onClick={goToNextPage}
        showCustomerViews
        handleScrollBarWidth
      />
    </>
  )
}

export const Summary = () => {
  const { state } = useAppState()
  const { routeTo } = useRouteTransition()
  const { quoteId } = useParams()
  const { setTitle } = useTitle()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setTitle('Yfirlit')
  })
  const policies = (state.quote && state.quote.policies) || []
  const props = {
    policies: policies,
    goToNextPage: async () => {
      setIsLoading(true)
      const policyOverview = await api.getPolicyOverview()

      const references = policyOverview.map((p) => p.subject.reference)

      const currentPolicies = getCarsThatAreAlreadyInsured(policies, references)

      let cancellationCompanies = [] as string[]
      if (state.quote) {
        const cancellations = await api.getCancellations(state.quote.number)

        if (cancellations.length > 0) {
          cancellationCompanies = cancellations.map((cancellation) => cancellation.insuranceCompanyName)
        }
      }
      setIsLoading(false)

      const routeEnd = `/${quoteId}${getRouteEnd(cancellationCompanies, currentPolicies)}`

      routeTo(routeEnd)
    },
    isLoading,
  }
  return <SummaryComponent {...props} />
}

const Content = styled.div`
  max-width: 90rem;
  margin: 0 auto;
`
const Policies = styled.section`
  ${breakpointUp('desktopL')} {
    max-width: 90rem;
    margin: 0 auto;
  }
  ${breakpointDown('tablet')} {
    margin-bottom: 9.375rem;
  }
`

const Title = styled.div<{ $isMounted: boolean }>`
  max-width: 18.75rem;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 900;
  margin: 3.5rem 1.5rem 2rem;
  text-transform: uppercase;
  color: ${colors.charcoalBlack};
  opacity: 0.01;
  transform: translateY(10vh);
  transition: all 0.3s ease-out 0.3s;
  ${(props) => props.$isMounted && 'transform: translateY(0); opacity: 1;'}

  ${breakpointUp('phone')} {
    font-size: 4rem;
    margin-top: 14rem;
  }
  ${breakpointUp('tablet')} {
    margin: 7.5rem 6.5rem 2.5rem;
  }
  ${breakpointUp('desktop')} {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
  }
`

const Subtitle = styled.p<{ $isMounted: boolean }>`
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${colors.steelGray};
  transform: translateY(10vh);
  transition: all 0.3s ease-out 0.4s;
  margin: 0 1.5rem 4rem;
  max-width: 43.75rem;
  opacity: 0.01;
  ${(props) => props.$isMounted && 'transform: translateY(0); opacity: 1;'}
  ${breakpointUp('phone')} {
    font-size: 1.75rem;
  }
  ${breakpointUp('tablet')} {
    margin: 0 6.5rem 7.5rem;
  }
`

const LogOutButtonWrapper = styled.div`
  position: relative;
  margin-left: 8.5rem;
`
