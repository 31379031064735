import React from 'react'
import { createRoot } from 'react-dom/client'
// import * as Sentry from '@sentry/react'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { isProd, isDev } from './helpers/env'

if (isProd || isDev) {
  // TODO: Temporarily commented out to prevent events being sent to Kvika Sentry (even though this should point to tmtryggingar Sentry :/)
  // Sentry.init({
  //   dsn: 'https://6eaa4ae7f79ae377788c7a5cebebfa38@o4507650683502592.ingest.de.sentry.io/4508619265802320',
  //   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  //   // Tracing
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   environment: isProd ? 'Production' : 'Development',
  // })
}
const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
