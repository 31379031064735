import 'react-app-polyfill/ie11'
import 'core-js'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import { Theme } from './Theme'
import { ErrorNotification } from './components/ErrorNotification'
import { AuthenticateRS, ReAuthenticateRS } from './routes/AuthenticateRS'
import { AuthenticateUserPass, ReAuthenticateUserPass } from './routes/AuthenticateUserPass'
import { Checkout } from './routes/Checkout'
import Details from './routes/Details'
import AllDone from './routes/Finished'
import LicencePlate from './routes/LicencePlate'
import PersonDetails from './routes/PersonDetails'
import SendOffer from './routes/SendOffer'
import { Store, useAppState } from './Store'
import { DynamicFavicon } from './components/DynamicFavicon'
import { AllComponents } from './routes/AllComponents'
import ThankYou from './routes/ThankYou'
import { Curtain } from './components/Curtain'
import { CurtainContext } from './hooks/useRouteTransition'
import { HeaderLogo } from './components/HeaderLogo'
import { LogOutButton } from './components/LogOutButton'
import { Breadcrumbs } from './components/Breadcrumbs'
import { Link } from 'react-router-dom'
import Agreement from './routes/Agreement'
import Terms from './routes/Terms'
import { isProd } from './helpers/env'

import { CarAdded } from './routes/SelfService/CarAdded'
import { CarKasko } from './routes/SelfService/CarKasko'
import { CarSelfRisk } from './routes/SelfService/CarSelfRisk'
import { CarKaskoAmount } from './routes/SelfService/CarKaskoAmount'
import { AddAnotherCar } from './routes/SelfService/AddAnotherCar'
import { DetailsReminder } from './routes/SelfService/DetailsReminder'
import { Cancellation } from './routes/SelfService/Cancellation'
import { Summary } from './routes/Summary/Summary'
import CarAlreadyInsured from './routes/SelfService/CarAlreadyInsured'
import { Welcome } from './routes/SelfService/Welcome'
import { ContinueOrStartAgain } from './routes/ContinueOrStartAgain'
import { LoggedOut } from './routes/LoggedOut'
import { breakpointDown } from './helpers/breakpoints'
import { OfferExpired } from './routes/OfferExpired'
import { SiteStatus } from './api/types'
import { api } from './api'
import { Closed } from './routes/SelfService/Closed'
import { AdminClosedUserPass } from './routes/AdminClosed'

const App = () => {
  const [isCurtainVisible, setIsCurtainVisible] = useState<boolean>(false)
  const [inMaintenanceMode, setInMaintenanceMode] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      api.getSiteStatus().then((siteStatus: SiteStatus) => {
        if (siteStatus.closed !== inMaintenanceMode) {
          setInMaintenanceMode(siteStatus.closed)
        }
      })
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const UserRoutes = () => {
    const { state } = useAppState()
    return (
      <>
        <Header>
          <HeaderLogo />
          {state.person && <LogOutButton />}
        </Header>
        <Root>
          <Routes>
            <Route path="/upphaf/:quoteId?" element={inMaintenanceMode ? <Closed /> : <Welcome />} />
            <Route path="/samthykki/:quoteId?" element={<Agreement />} />
            <Route path="/skilmalar/:quoteId?" element={<Terms />} />
            <Route path="/audkenna/:quoteId?" element={<AuthenticateRS />} />
            <Route path="/audkenna-aftur/:quoteId?" element={<ReAuthenticateRS />} />
            <Route path="/taka-upp-thradinn/:quoteId?" element={<ContinueOrStartAgain />} />
            <Route path="/:quoteId/yfirlit/:policyId" element={<Details />} />
            <Route path="/:quoteId/kaupa" element={<Checkout />} />
            <Route path="/:quoteId/takk-fyrir" element={<ThankYou />} />
            <Route path="/:quoteId/sla-inn-bilnumer" element={<LicencePlate isAdmin={false} />} />
            <Route path="/:quoteId/bill-kynning/:policyId" element={<CarAdded />} />
            <Route path="/:quoteId/kasko-trygging/:policyId" element={<CarKasko />} />
            <Route path="/:quoteId/kasko-eiginahaetta/:policyId" element={<CarSelfRisk />} />
            <Route path="/:quoteId/kasko-upphaed/:policyId" element={<CarKaskoAmount />} />
            <Route path="/:quoteId/tryggja-annan-bil" element={<AddAnotherCar />} />
            <Route path="/:quoteId/minna-a-tryggingar" element={<DetailsReminder />} />
            <Route
              path="/:quoteId/bill-thegar-tryggdur/:insuranceCompanies/:plateNumber?"
              element={<CarAlreadyInsured />}
            />
            <Route path="/:quoteId/yfirlit" element={<Summary />} />
            <Route path="/:quoteId/uppsogn" element={<Cancellation />} />
            <Route path="/:quoteId/tilbod-utrunnid" element={<OfferExpired />} />
            <Route path="/utskraning" element={<LoggedOut />} />
            {/* Redirects */}
            <Route path="/" element={<Navigate replace to="/upphaf/:quoteId?" />} />
          </Routes>
        </Root>
      </>
    )
  }

  const AdminRoutes = () => {
    return (
      <>
        <Header $isAdmin>
          <Link to={`kaupandi`} tabIndex={1}>
            <HeaderLogo />
          </Link>
          <Breadcrumbs />
        </Header>
        <Root>
          <Routes>
            <Route path={`audkenna`} element={inMaintenanceMode ? <AdminClosedUserPass /> : <AuthenticateUserPass />} />
            <Route
              path={`audkenna-aftur`}
              element={inMaintenanceMode ? <AdminClosedUserPass /> : <ReAuthenticateUserPass />}
            />
            <Route path={`kaupandi`} element={<PersonDetails />} />
            <Route path={`:quoteId/okutaeki`} element={<LicencePlate isAdmin={true} />} />
            <Route path={`:quoteId/yfirlit/:policyId`} element={<Details />} />
            <Route path={`:quoteId/senda-tilbod`} element={<SendOffer />} />
            <Route path={`:quoteId/vel-gert`} element={<AllDone />} />
            <Route path="*" element={<Navigate replace to="audkenna" />} />
          </Routes>
        </Root>
      </>
    )
  }

  return (
    <Router>
      <CurtainContext.Provider value={[isCurtainVisible, setIsCurtainVisible]}>
        <DynamicFavicon key="favicon" />
        <ErrorNotification key="errorNotification" />
        <Curtain key="curtain" visible={isCurtainVisible} />
        <Routes key="routeSwitch">
          {!isProd && <Route path="/components" element={<AllComponents />} />}
          <Route path="/admin/*" element={<AdminRoutes />} />
          <Route path="/*" element={<UserRoutes />} />
        </Routes>
      </CurtainContext.Provider>
    </Router>
  )
}

const AppWrapper = () => {
  return (
    <Store>
      <Theme>
        <App />
      </Theme>
    </Store>
  )
}

export default AppWrapper

const Root = styled.main`
  overflow-y: hidden; /* This prevents a momentary appearance of a vertical scrollbar when ChatSection is animating in. */
`

const Header = styled.header<{ $isAdmin?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$isAdmin ? 'center' : 'flex-end')};
  justify-content: center;
  position: relative;
  height: 5rem;
  ${breakpointDown('phone')} {
    flex-direction: unset;
    justify-content: space-between;
    white-space: nowrap;
  }
`
