import React from 'react'
import styled from 'styled-components'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { api } from '../api/api.tm'
import { breakpointUp, breakpointDown } from '../helpers/breakpoints'
import { useAppState, clearState } from '../Store'
import { colors } from '../constants'

interface Props {
  invisible?: boolean
}

export const LogOutButton = ({ invisible }: Props) => {
  const { routeTo } = useRouteTransition()
  const { dispatch } = useAppState()
  const logOut = async () => {
    window.sessionStorage.clear()
    dispatch(clearState)
    await api.logOut()
    routeTo('/utskraning')
  }
  return (
    <Button onClick={logOut} aria-label="Útskráning" $invisible={invisible}>
      Skrá út
    </Button>
  )
}

const Button = styled.button<{ $invisible?: boolean }>`
  justify-self: end;
  font-weight: 500;
  font-size: 1.125rem;
  margin-right: 1.5rem;
  color: ${colors.charcoalBlack};
  transition: all 0.2s ease-out;
  background: linear-gradient(to right, ${colors.silverGray} 50%, transparent 50%);
  background-size: 200% 34%;
  background-repeat: no-repeat;
  text-decoration: none;
  background-position: right bottom;
  cursor: pointer;
  padding: 0;
  border: none;
  border-bottom: 0.125rem solid ${colors.charcoalBlack};

  ${breakpointUp('phone')} {
    &:hover {
      background-position: left bottom;
    }
  }
  ${breakpointDown('phone')} {
    margin-bottom: 1.5rem;
  }

  /* The following rules are for displaying the lower logout button only on keyboard navigation */
  ${(props) =>
    props.$invisible && 'position: absolute; bottom: 5rem; opacity: 0; &:focus{ bottom: 10rem; opacity: 1; }'}
`
