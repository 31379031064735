import React, { useEffect, useState } from 'react'

import { PageHeader } from '../components/PageHeader'
import styled from 'styled-components'
import { colors } from '../constants'
import { Quote, Brand } from '../api/types'
import { Loading } from '../components/Loading'
import { formatKronur } from '../helpers/formatKronur'
import { maxWidth, breakpointUp, breakpointDown } from '../helpers/breakpoints'
import { useMedia } from '../helpers/useMedia'
import { CheckoutTable } from './Checkout/CheckoutTable'
import { propBetween } from '../helpers/between'
import { useTitle } from '../hooks/useTitle'
import { ThankYouPageFooter } from '../components/ThankYouPageFooter'
import { useAppState } from '../Store'

interface Props {
  name: string
  email: string
  quote: Quote
}

const firstName = (name: string) => name.split(' ')[0]

const ThankYouComponent = ({ name, email, quote }: Props) => {
  const isMobile = useMedia(maxWidth('tablet'))
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const { state } = useAppState()
  const isSelfService = state.quote && state.quote.createdByCurrentUser
  useEffect(() => {
    const timeoutId = setTimeout(() => setIsMounted(true), 0)
    return () => {
      clearTimeout(timeoutId)
    }
  })
  return (
    <>
      <PageHeader>
        <>
          <PageHeadingContainer>
            {isMobile ? (
              <>
                <PageHeading>Takk fyrir!</PageHeading>
              </>
            ) : (
              <>
                <PageHeading>Takk fyrir,</PageHeading>
                <br />
                <PageHeading>{firstName(name)}!</PageHeading>
              </>
            )}
          </PageHeadingContainer>
          {isSelfService && (
            <InfoContentContainer>
              <InfoContent $isMounted={isMounted}>
                <Title $isMounted={isMounted}>Kaskóskoðun</Title>
                <Text $isMounted={isMounted}>
                  Áður en kaskótryggingin tekur gildi þarftu að klára einfalda kaskóskoðun í TM appinu.
                </Text>
              </InfoContent>
            </InfoContentContainer>
          )}
        </>
      </PageHeader>
      <Content>
        <Paragraph>
          Þá er þetta komið! Innan skamms færðu staðfestingarpóst á {email} með upplýsingum um nýju trygginguna þína.
        </Paragraph>

        <h2>Hér er það sem þú keyptir</h2>
        {isMobile ? (
          <CheckoutTable policies={quote.policies} showHeading={false} />
        ) : (
          <ThankYouTable>
            {quote.policies.map((policy, index) => (
              <>
                <thead>
                  <tr>
                    <th>{policy.product.name}</th>
                    <th>Viðbætur</th>
                    <th align="right">{formatKronur(policy.policyPeriod.price.total || 0)} kr. / ári</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {policy.subjects[0].description}, {policy.subjects[0].modelYear}
                    </td>
                    <td>
                      <ul>
                        {policy.subjects[0].coverage
                          .filter((x) => x.optional && x.selected)
                          .map((x) => (
                            <li key={x.name}>{x.name}</li>
                          ))}{' '}
                      </ul>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </>
            ))}
          </ThankYouTable>
        )}
      </Content>
      <ThankYouPageFooter />
    </>
  )
}

const InfoContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-self: flex-end;

  ${breakpointUp('tablet')} {
    justify-content: flex-end;
    padding-right: 16.25rem;
    padding-left: 7.5rem;
  }
`
const InfoContent = styled.div<{ $isMounted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1rem;
  background-color: ${colors.paleGray};
  transform: ${(props) => (props.$isMounted ? 'translateY(7.5rem);' : 'translateY(20vh)')};
  transition: transform, opacity 0.3s cubic-bezier(0, 0, 0, 1);
  opacity: ${(props) => (props.$isMounted ? '1' : '0.01')};
  max-width: 33.125rem;
  padding: 3.5rem 3rem;
  margin-bottom: 4rem;

  ${breakpointUp('desktopL')} {
    padding: 6.5rem 4rem;
  }
  ${breakpointDown('phone')} {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
    padding: 3.5rem 2rem;
  }
`
const Title = styled.h1<{ $isMounted: boolean }>`
  ${propBetween('font-size', 2.5, 3.75)};
  ${propBetween('margin-bottom', 2, 3.5)};
  line-height: 1.2;
  font-weight: 700;
  padding-right: 1rem;
  margin-top: 0;
  transform: ${(props) => (props.$isMounted ? 'translateY(0);' : 'translateY(10vh)')};
  transition: transform 0.5s cubic-bezier(0, 0, 0, 1) 0.1s;
  opacity: ${(props) => (props.$isMounted ? '1' : '0.01')};
  /* IE11 needs to be told explicitly that children of flex parents shouldn't overflow them */
  width: 100%;
`
const Text = styled.p<{ $isMounted: boolean }>`
  ${propBetween('font-size', 1.125, 1.6875)};
  line-height: 1.5;
  transform: ${(props) => (props.$isMounted ? 'translateY(0);' : 'translateY(10vh)')};
  opacity: ${(props) => (props.$isMounted ? '1' : '0.01')};
  transition: opacity, transform 0.5s cubic-bezier(0, 0, 0, 1) 0.2s;
  margin: 0;
`
const ThankYouTable = styled.table`
  width: 100%;
  text-align: left;

  ul {
    list-style-type: none;
    padding: 0;
  }
`

const ThankYou = () => {
  const { state } = useAppState()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Takk fyrir')
  }, [setTitle])
  if (!state.quote || !state.person) {
    return <Loading />
  }
  return (
    <ThankYouComponent
      quote={state.quote}
      email={(state.person.email && state.person.email.personal) || 'netfangið þitt'}
      name={state.person.name || ''}
    />
  )
}

const Content = styled.div`
  padding: 0;
  ${propBetween('padding-left', 1.5, 6.5)};
  ${propBetween('padding-right', 1.5, 6.5)};
  ${breakpointUp('desktopL')} {
    padding: 0;
    width: 77rem;
    margin-left: auto;
    margin-right: auto;
  }
  ${breakpointDown('tablet')} {
    padding-top: 7em;
  }
`

const PageHeadingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding-top: 7.5rem;
  padding-left: 6.5rem;
  ${breakpointDown('tablet')} {
    padding-left: 1.5rem;
    padding-top: 2.5rem;
  }
`

const PageHeading = styled.h1`
  display: inline;

  padding: 0.25rem 1rem;

  color: ${(props) => (props.theme.type === Brand.Lexus ? colors.white : colors.black)};
  background: ${(props) => (props.theme.type === Brand.Lexus ? 'transparent' : colors.platinumGray)};

  ${propBetween('font-size', 2.1875, 3.75)}
  text-transform: uppercase;
  white-space: pre;
`

const Paragraph = styled.p`
  display: block;
  max-width: 44rem;
  margin: 0;
  margin-top: 2rem;
  padding: 6.5rem 0;

  ${propBetween('font-size', 1.125, 1.6875)}

  ${breakpointDown('tablet')} {
    padding: 1rem 0;
  }
`

export default ThankYou
