import React, { useRef } from 'react'
import styled from 'styled-components'

import { colors } from '../constants'
import { Close } from '../icons/Close'

import { Input } from './Input'
import { breakpointUp, breakpointDown } from '../helpers/breakpoints'

interface Props {
  handleInputChange: (event: string) => any
  onReturn?: () => any
  onClose?: () => any
  showInfo: boolean
  infoText: string | React.ReactNode
  inputValue: string
  inputLabel: string
  error?: string
  isNumericKeyBoard?: boolean
  type?: 'text' | 'email' | 'tel' | 'password'
  signatureControlCode?: string
}

export const InputInfoBox: React.FunctionComponent<Props> = ({
  handleInputChange,
  onReturn,
  onClose,
  showInfo,
  infoText,
  inputLabel,
  inputValue,
  error,
  isNumericKeyBoard,
  type = isNumericKeyBoard ? 'tel' : 'text',
  signatureControlCode,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const clickClose = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (onClose && inputRef.current) {
      inputRef.current.focus()
      onClose()
    }
  }
  const showCode = signatureControlCode && !error

  return (
    <>
      <InputSpan $showInfo={showInfo} $error={error}>
        {
          <Input
            error={error}
            label={inputLabel}
            onChange={(event) => handleInputChange(event)}
            value={inputValue}
            type={type}
            onReturn={onReturn}
            autoComplete="off"
            ref={inputRef}
          />
        }
      </InputSpan>

      <InfoBox $showInfo={showInfo} $error={error} className="InfoBox">
        <span className={'text'}>{infoText}</span>
        {onClose && (
          <ClearButton onClick={() => clickClose(inputRef)}>
            <Close />
          </ClearButton>
        )}
      </InfoBox>
      {showCode && (
        <SCVerificationCode>
          <SCCode>
            Staðfestu aðeins ef auðkennisnúmerið <strong>{signatureControlCode}</strong> birtist í símanum
          </SCCode>
        </SCVerificationCode>
      )}
    </>
  )
}

const InputSpan = styled.span<{ $showInfo: boolean; $error?: string }>`
  display: inline-block;
  width: 100%;
  max-width: 25rem;
  opacity: ${(props) => (props.$showInfo && !props.$error ? 0 : 1)};
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: ${(props) => props.$showInfo && !props.$error && 'translateY(-4rem)'};
`

const InfoBox = styled.div<{ $showInfo: boolean; $error?: string }>`
  margin-top: -2.5rem;
  padding: 1.125rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.white};
  border: 0.0625rem;
  border-radius: 0.25rem;
  height: 4.5rem;
  width: 100%;
  box-shadow: 0 1.875rem 4.875rem rgba(0, 0, 0, 0.16);
  opacity: ${(props) => (props.$showInfo && !props.$error ? 1 : 0)};
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: ${(props) => props.$showInfo && !props.$error && 'translateY(-3rem)'};

  .text {
    font-size: 1rem;
    color: ${colors.black};
    text-align: center;
  }

  ${breakpointUp('tablet')} {
    margin-top: -1.5rem;
    padding: 1.125rem 2rem;
    max-width: 23rem;
    height: 4.5rem;
    transform: ${(props) => props.$showInfo && !props.$error && 'translateY(-4rem)'};

    .text {
      font-size: 1.125rem;
    }
  }

  ${breakpointDown('tablet')} {
    max-width: 25rem;
  }
`
const ClearButton = styled('button')`
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  margin-left: 8px;
`

const SCVerificationCode = styled.div`
  margin-bottom: 3.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
const SCCode = styled.p`
  font-size: 0.75rem;
  margin-bottom: 0;
`
