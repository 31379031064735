import React, { InputHTMLAttributes, Ref } from 'react'
import styled from 'styled-components'

import { colors } from '../constants'
import { breakpointUp, breakpointDown } from '../helpers/breakpoints'

interface Props {
  value: string
  onChange: (value: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  error?: string
  label: string
  onReturn?: (value: string) => any
  onBlur?: () => void
  type?: 'text' | 'email' | 'tel' | 'password'
  disabled?: boolean
  autoComplete?: 'off'
}

export const Input = React.forwardRef(
  (
    { value, onChange, error, label, onReturn, onBlur, type = 'text', disabled, autoComplete }: Props,
    ref?: React.Ref<any>
  ) => (
    <Root>
      <InputElement
        required
        id={`input_${label}`}
        ref={ref}
        onChange={(event) => onChange(event.target.value, event)}
        onKeyPress={(event) => event.key === 'Enter' && onReturn && onReturn(value)}
        type={type}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={label}
        autoComplete={autoComplete}
        $error={error ? true : false}
      />
      <Label htmlFor={`input_${label}`}>{label}</Label>

      <Error>{error}&nbsp;</Error>
    </Root>
  )
)

interface WrappedInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string
}

export const WrappedInput = React.forwardRef(({ error, ...props }: WrappedInputProps, ref?: Ref<HTMLInputElement>) => {
  return (
    <Root>
      <InputElement {...props} id={`input_${props.placeholder}`} $error={Boolean(error)} ref={ref} />
      <Label htmlFor={`input_${props.placeholder}`}>{props.placeholder}</Label>

      <Error>{error}&nbsp;</Error>
    </Root>
  )
})

const InputElement = styled.input<{ $error?: boolean }>`
  width: 100%;
  padding: 1.125rem 1rem;

  background-color: ${colors.paleGray};
  border: none;
  border-radius: 0.25rem;

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;

  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.silverGray};
  }

  &::placeholder {
    color: ${colors.gray};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.0625rem ${colors.gray};
  }

  ${(props) =>
    props.$error &&
    `
    @keyframes shake {
      8%,
      41% {
        transform: translateX(-0.625rem);
      }
      25%,
      58% {
        transform: translateX(0.625rem);
      }
      75% {
        transform: translateX(-0.3125rem);
      }
      92% {
        transform: translateX(0.3125rem);
      }
      0%,
      100% {
        transform: translateX(0);
      }
    }

    animation-name: shake;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
  `}
`

const Root = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  margin-top: 1.25rem;

  ${breakpointUp('tablet')} {
    font-size: 1.125rem;
  }

  ${breakpointDown('phone')} {
    font-size: 1rem;
  }
`

const Label = styled.label`
  position: absolute;
  top: -1.5rem;
  left: -0.11rem;
  display: flex;
  opacity: 1;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${colors.steelGray};
  transition: 0.3s ease-out all;

  [value=''] ~ & {
    opacity: 0;
    top: -1.25rem;
  }
`

const Error = styled.span`
  max-width: 24rem;
  margin-top: 0.25rem;
  padding-left: 0.0625rem;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  color: ${colors.errorRed};
`
