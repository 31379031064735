export type Person = {
  name: string
  kennitala: string
  age: number
  gender: 'Male' | 'Female'
  settings: CustomerSettings
  isCustomer: boolean
  email: {
    personal: string
  }
  phone: {
    mobile: string
    eIdMobile: string
  }
}

export interface CustomerSettings {
  wantsMarketing: 'Yes' | 'No'
  paperLess: 'Yes' | 'No'
}

export interface User {
  id: string
  customer: Person
}

export interface QuoteStatus {
  type: 'InProgress' | 'Rejected' | 'Accepted'
}
export interface Product {
  name: string
  number: string
  variant: string
}
export interface Option {
  id: string
  value: string
}
export interface OptionProperty {
  __typename: 'OptionsProperty'
  id: string
  name: string
  selection: Option | null
  options: Array<Option>
}
export interface EnteredNumberProperty {
  __typename: 'EnteredNumberPropertyType'
  id: string
  name: string
  value: number | null
  message?: {
    type: 'warn' | 'error'
    value: string
  }
  valueOptions: Array<number>
  unit: string
}
export interface StaticNumberProperty {
  __typename: 'StaticNumberPropertyType'
  id: string
  name: string
  value: number | null
  unit: string
}
export interface StaticTextProperty {
  __typename: 'StaticTextPropertyType'
  id: string
  name: string
  text?: string
}
export interface PropertyGroupType {
  __typename: 'PropertyGroupType'
  id: string
  name: string
  properties: Array<Property>
  text?: string
}

export type Property =
  | OptionProperty
  | EnteredNumberProperty
  | StaticNumberProperty
  | StaticTextProperty
  | PropertyGroupType

export interface Coverage {
  typeId: string
  name: string
  covered: Array<string>
  notCovered: Array<string>
  selected: boolean
  optional: boolean
  properties: Array<Property>
  entryPriceTotal: {
    total: number | null
  }
}
export interface BaseSubject {
  __typename: string
  // For cars it's the license plate, for person's it's the kennitala ect.
  // [Tilvísun]
  reference: string
  // For houses it's the address, for person's it's the name ect.
  description: string
  // Each subject can have properties like for example: insurance amount, discount, surcharge
  properties: Array<Property>
  coverage: Array<Coverage>
}
export interface VehicleSubject extends BaseSubject {
  __typename: 'VehicleSubject'
  modelYear: number
}
export interface PolicyPeriod {
  start?: string
  end?: string
  // What the customer needs to pay for this period. [Iðgjald, Verð]
  price: {
    total: number | null
  }
}

export type Subject = VehicleSubject

export interface Policy {
  product: Product
  // Policy period (Vátryggingar tímabil).
  policyPeriod: PolicyPeriod
  number: string
  // id of the insured customer
  subjects: Array<VehicleSubject>
  terms: Array<PolicyTerms>
}

export interface PolicyTerms {
  name: string
  url: string
}
export interface Quote {
  number: string
  kennitala: string
  status?: QuoteStatus
  policies: Array<Policy>
  createdByCurrentUser: boolean
  editableUntilDate: string
}

export interface EnteredNumberPropertyInput {
  id: string
  value: string
}
export type PropertyInput = EnteredNumberPropertyInput
export interface CoverageInput {
  typeId: string
  selected?: boolean
  properties?: Array<PropertyInput>
}
export interface SubjectItemInput {
  description: string
  value: number
}
export interface SubjectInput {
  properties?: Array<PropertyInput>
  coverage?: Array<CoverageInput>
  items?: Array<SubjectItemInput>
}

export interface Message {
  type: 'error' | 'warn'
  value: string
}
export interface PolicyResult {
  success: boolean
  messages: Array<Message>
  policy: Policy
}
export interface VehicleInfo {
  model: string
  year: string
  power: string
  fuelType: string
}

export interface VehicleModel {
  model: SupportedModels
  licencePlate: string
}

export enum SupportedModels {
  Toyota = 'Toyota',
  Lexus = 'Lexus',
}

export enum OtherModels {
  UnsupportedModels = 'UnsupportedModels',
}
export type AllModels = SupportedModels | OtherModels

export enum Brand {
  Lexus = 'Lexus',
  Toyota = 'Toyota',
  Unknown = 'Unknown',
}

export interface CancellationPolicy {
  productName: string
  productNumber: string
  subjectDescription: string
  dueDate: string
}

export interface Cancellation {
  insuranceCompanyName: string
  policies: Array<CancellationPolicy>
}

export interface CancellationFile {
  id: string
  url: string
  signed: boolean
}

export interface StartSigningCancellationResult {
  controlCode: string
}
export enum SignCancellationStatus {
  Success = 'Success',
  UserCanceled = 'UserCanceled',
  Timeout = 'Timeout',
  FileNotFound = 'FileNotFound',
  FileAlreadySigned = 'FileAlreadySigned',
  SignatureNotPending = 'SignatureNotPending',
}
export interface SendSignedCancellationResult {
  status: SignCancellationStatus
}
export type PaymentSchedule = 'monthly' | 'yearly'
export interface CreditCardPaymentMethod {
  type: 'creditCard'
  cardNumber: string
  validTo: {
    month: number
    year: number
  }
  cvc: string
  schedule: PaymentSchedule
}
export interface InvoicePaymentMethod {
  type: 'invoice'
  schedule: PaymentSchedule
}

export interface UseExistingPaymentMethod {
  type: 'useExisting'
}
export type PaymentMethod = CreditCardPaymentMethod | InvoicePaymentMethod | UseExistingPaymentMethod

export interface CheckoutArguments {
  quoteId: string
  method: PaymentMethod
  email: String
  paperLess: boolean
  wantsMarketing: boolean
}

export interface PolicyOverviewSubject {
  reference: string
  description: string
}

export interface PolicyOverview {
  subject: PolicyOverviewSubject
}

export type CheckoutMessageCode =
  | 'cardNotAuthorized'
  | 'cardServiceConnectionFailure'
  | 'invalidCreditCardNumber'
  | 'invalidInput'
  | 'unknown'
export interface CheckoutMessage extends Message {
  code: CheckoutMessageCode
}

export interface CheckoutResult {
  success: boolean
  messages: Array<CheckoutMessage>
  cancellations: Array<CancellationFile>
}

export type SiteStatus = {
  closed: boolean
  title?: string
  message?: string
}

export interface LoginVerification {
  hash: string
  verificationCode: string
}

export type AudkenniLoginChoice = 'SIM' | 'APP'

export interface Api {
  startLoginRS: () => Promise<LoginVerification>
  loginRS: (
    ktOrPhoneNumber: string,
    choice: AudkenniLoginChoice,
    hash: string,
    verificationCode: string
  ) => Promise<User>
  loginUserPass: (username: string, password: string) => Promise<Person>
  logOut: () => Promise<void>
  getCustomer: () => Promise<Person>
  getSiteStatus: () => Promise<SiteStatus>
  getPolicyOverview: () => Promise<Array<PolicyOverview>>
  getCustomerNameByKennitala: (kennitala: string) => Promise<string>
  updateContactInfo: (kennitala: string, phoneNumber: string, email: string) => Promise<Person>
  updateCustomerSettings: (settings: CustomerSettings) => Promise<CustomerSettings>
  getQuote: (quoteId: string) => Promise<Quote>
  getQuoteInProgress: () => Promise<Quote | undefined>
  createQuote: (kennitala?: string) => Promise<Quote>
  updatePolicy: (policyNumber: string, subject: SubjectInput) => Promise<Policy>
  removePolicy: (policyNumber: string) => Promise<void>
  addVehicle: (quoteId: string, kennitala: string, licencePlate: string, model: SupportedModels) => Promise<Policy>
  getVehicleInfo: (plateNumber: string) => Promise<VehicleInfo>
  getCancellations: (quoteId: string) => Promise<Array<Cancellation>>
  signCancellation: (fileId: string, phoneNumber: string) => Promise<string>
  startSigningCancellation: (phoneNumber: string, cancellationId: string) => Promise<StartSigningCancellationResult>
  sendSignedCancellation: (cancellationId: string) => Promise<SendSignedCancellationResult>
  checkout: (args: CheckoutArguments) => Promise<CheckoutResult>
  sendSMS: (phoneNumber: string, message: string) => Promise<boolean>
}
